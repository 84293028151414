<template>
  <div class="card-row">
    <div class="drag-handle" v-if="order === ''"></div>
    <CustomCheckbox
      v-if="isCheckboxShown"
      :isCheckboxChecked="isCheckboxChecked"
      @click="handleCheckboxClick"
    />
    <EditableField
      v-if="name && isNameEditable"
      :value="name"
      :isSubscriptionActive="isSubscriptionActive"
      @valueUpdated="$emit('updateName', $event)"
    />
    <div v-if="name && !isNameEditable" class="not-editable-field">
      {{ name }}
    </div>
    <EditableField
      v-if="company && isCompanyEditable"
      :value="company"
      :isSubscriptionActive="isSubscriptionActive"
      @valueUpdated="$emit('updateCompany', $event)"
    />
    <div v-if="company && !isCompanyEditable" class="not-editable-field">
      {{ company }}
    </div>
    <div
      class="not-editable-field pipeline-status"
      :class="{
        'pipeline-status-not-in-pipeline':
          clientPipelineStatus === customerPipelineStatus[0],
        'pipeline-status-in-pipeline':
          clientPipelineStatus === customerPipelineStatus[1],
      }"
      v-if="clientPipelineStatus"
      @click="$emit('switchClientPipelineStatus')"
    >
      {{ clientPipelineStatus }}
    </div>
    <div v-if="statuses" class="prospect-statuses-btns">
      <div
        class="prospect-status-btn"
        :class="{ 'active-radio-btn': currentItemStatus === status }"
        v-for="(status, index) in statuses"
        :key="index"
        @click="$emit('updateStatus', status)"
      ></div>
    </div>
    <div v-if="isNoteDisabled" class="pipeline-row-note-field">
      {{ note ?? '-' }}
    </div>
    <div v-else class="pipeline-row-note-field">
      <TableNoteField :value="note" @pipelineNoteUpdated="handleNoteUpdate" />
    </div>
    <div class="action-btn-container">
      <MoreActionsBtn
        :width="'32px'"
        :height="'32px'"
        :isActive="isActionMenuShown"
        @click="switchActionMenuState"
      />
      <ActionMenu
        :width="actionMenuWidth"
        :height="actionMenuHeight"
        :top="actionMenuTop"
        :isActionMenuShown="isActionMenuShown"
        @closeActionMenu="closeActionMenu"
      >
        <ActionMenuItem
          v-for="(action, index) in menuActions"
          :key="index"
          :text="action"
          @actionMenuItemClick="handleActionMenuItemClick"
        />
      </ActionMenu>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from '../CustomCheckbox.vue'
import EditableField from '../EditableField.vue'
import MoreActionsBtn from '../MoreActionsBtn.vue'
import ActionMenu from '../actionMenu/ActionMenu.vue'
import ActionMenuItem from '../actionMenu/ActionMenuItem.vue'
import CustomSwitcher from '../mainContent/CustomSwitcher.vue'
import TableNoteField from '../../common/mainContent/TableNoteField.vue'

import { CustomerPipelineStatus } from '../../../utils/constants'
import { updateNoteValue } from '../../../api/pipelinesApi'

export default {
  props: [
    'id',
    'order',
    'note',
    'isCheckboxShown',
    'selectedRows',
    'name',
    'company',
    'statuses',
    'currentItemStatus',
    'menuActions',
    'actionMenuWidth',
    'actionMenuHeight',
    'actionMenuTop',
    'isNameEditable',
    'isCompanyEditable',
    'isSubscriptionActive',
    'clientPipelineStatus',
    'isNoteDisabled',
  ],
  components: {
    CustomCheckbox,
    EditableField,
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
    CustomSwitcher,
    TableNoteField,
  },
  data() {
    return {
      isCheckboxChecked: false,
      isActionMenuShown: false,
      customerPipelineStatus: CustomerPipelineStatus,
    }
  },
  created() {
    this.isCheckboxChecked = this.selectedRows.includes(this.id)
  },
  watch: {
    selectedRows(newValue, _) {
      if (newValue.includes(this.id)) this.isCheckboxChecked = true
      else this.isCheckboxChecked = false
    },
  },
  methods: {
    handleCheckboxClick() {
      if (this.isCheckboxChecked) {
        this.isCheckboxChecked = false
        this.$emit('removeSelection')
      } else {
        this.isCheckboxChecked = true
        this.$emit('checkboxSelect')
      }
    },
    switchActionMenuState() {
      if (!this.isSubscriptionActive) return
      if (this.isActionMenuShown) this.isActionMenuShown = false
      else this.isActionMenuShown = true
    },
    closeActionMenu() {
      this.isActionMenuShown = false
    },
    handleActionMenuItemClick(action) {
      this.isActionMenuShown = false
      this.$emit('actionMenuItemClick', action)
    },
    async handleNoteUpdate(newValue) {
      if (newValue !== this.note) {
        await updateNoteValue(this.id, newValue)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.card-row {
  width: calc(100% - 80px);
  height: 44px;
  padding: 0 40px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;

  .drag-handle {
    width: 16px;
    height: 6px;
    position: absolute;
    top: calc(50% - 3px);
    left: 13px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url('../../../assets/icons/drag-handle-icon.svg');
    cursor: grab;
  }

  .pipeline-row-note-field {
    min-width: 250px;
    height: 20px;
    margin-left: 30px;
    display: block;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }

  .not-editable-field {
    width: 150px;
    height: 23px;
    margin-left: 30px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
  }

  .pipeline-status {
    height: 30px;
    display: flex;
    justify-content: center;
    color: $fontColorWhite;
    background: red;
    border-radius: 15px;
    cursor: pointer;
  }

  .pipeline-status-in-pipeline {
    background-color: $appActionColor;
  }

  .pipeline-status-not-in-pipeline {
    background-color: $redWarningColor;
  }

  &:hover {
    background-color: $hoveredItemColor;
  }

  .prospect-statuses-btns {
    width: 328px;
    margin: 0 30px;
    display: flex;
    justify-content: space-between;

    .prospect-status-btn {
      width: 20px;
      height: 20px;
      margin: 0 31px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../../assets/icons/radio-btn-off-icon.svg');
    }

    .active-radio-btn {
      background-image: url('../../../assets/icons/radio-btn-on-icon.svg');
    }
  }

  .action-btn-container {
    width: 42px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .more-actions-btn {
      border: none;
    }

    .more-actions-btn-active-state {
      border: 1px solid $grayBorderColor;
    }
  }
}

@media (max-width: 420px) {
  .card-row {
    width: calc(100% - 20px);
    padding: 0 10px;
    .drag-handle {
      margin-left: 20px;
    }
    .editable-field {
      .value-container {
        width: 70px;
      }
    }
  }
}
</style>
